<template>
  <div class="wrapper">
    <br><br><br><br>
    <div class="section">
      <div class="container">
        <div class="minting-bi-wrap">
          <img class="minting-bi" :src="image_head_title">
        </div>

        <div
          class="section section-team-4 section-image"
        >
          <!--:style="sectionTeam(team4)"-->
          <div class="container">

            <div class="md-layout">
              <div class="md-layout-item md-size-33 md-small-size-100">
                <ACProfileCard
                  card-avatar
                  class="planet-0"
                  :shadow-normal="false"
                  :no-colored-shadow="false"
                  :card-image="planet_1"
                >
                  <template slot="cardContent">
                    <h4 class="card-title">
                      {{language.schedule_event_1_title}}
                    </h4>
                    <h6 class="card-category text-muted">
                      {{language.schedule_event_1_round_1}}
                    </h6>
                    <p class="card-description">
                      {{language.schedule_event_1_round_2}}<br>
                      {{language.schedule_event_1_round_3}}<br>
                      {{language.schedule_event_1_round_4}}<br>
                    </p>
                  </template>
                </ACProfileCard>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <ACProfileCard
                  card-avatar
                  class="planet-1"
                  :shadow-normal="false"
                  :no-colored-shadow="false"
                  :card-image="planet_2"
                >
                  <template slot="cardContent">
                    <h4 class="card-title">
                      {{language.schedule_event_2_title}}
                    </h4>
                    <h6 class="card-category text-muted">
                      {{language.schedule_event_2_round_1}}
                    </h6>
                    <p class="card-description">
                      {{language.schedule_event_2_round_2}}<br>
                      {{language.schedule_event_2_round_3}}<br>
                      {{language.schedule_event_2_round_4}}<br>
                    </p>
                  </template>

                </ACProfileCard>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <ACProfileCard
                  card-avatar
                  class="planet-2"
                  :shadow-normal="false"
                  :no-colored-shadow="false"
                  :card-image="planet_3"
                >
                  <template slot="cardContent">
                    <h4 class="card-title">
                      {{language.schedule_event_3_title}}
                    </h4>
                    <h6 class="card-category text-muted">
                      {{language.schedule_event_3_round_1}}
                    </h6>
                    <p class="card-description">
                      {{language.schedule_event_3_round_2}}<br>
                      {{language.schedule_event_3_round_3}}<br>
                      {{language.schedule_event_3_round_4}}<br>
                    </p>
                  </template>

                </ACProfileCard>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import {
    ACProfileCard,
  } from "@/components";
  import AOS from "aos";
  import "aos/dist/aos.css";
  import Mixins from "@/plugins/basicMixins";

  // import { PricingCard, Modal } from "@/components";

  export default {
    bodyClass: "ac-nft-schedule",
    components: {
      ACProfileCard,
      // PricingCard,
      // Modal,
    },
    mixins: [Mixins.HeaderImage],
    created() {
      AOS.init();
    },
    computed: {
      ...mapGetters([
        "base",
        'language',
      ]),
    },
    data() {
      return {
        image_head_bg: require("@/assets/img/bg/00_pattern1.png"),
        image_head_title: require("@/assets/img/pages/nft/schedule.png"),



        planet_1: require("@/assets/img/pages/nft/planet_1.gif"),
        planet_2: require("@/assets/img/pages/nft/planet_2.gif"),
        planet_3: require("@/assets/img/pages/nft/planet_3.gif"),

      };
    }
  };
</script>

<style lang="scss">

  $ac-section-bg: #ff6371 !default;

  .ac-nft-schedule {
    color: white;

    .wrapper {
      /*position: relative;*/
      /*overflow: hidden;*/
      text-align: center;

      background-image: url("../../assets/img/pages/nft/03_nft_bg3.png");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: cover;

      .section {
        padding: 85px 0;

        .md-card {
          margin: 60px 0;
          background: #0c0a26;
          padding: 1rem 1rem 2rem;
          box-shadow: 0 4px 25px 0 rgb(104 37 204 / 80%);
          border-radius: 10px;
          
          &.planet-0 {
            .card-title {
              color: #28e5cc !important;
            }
            .card-category {
              background-color: #28e5cc;
              color: #0a0c26!important;
            }
          }

          &.planet-1 {
            .card-title {
              color: #9d5bff !important;
            }
            .card-category {
              background-color: #9d5bff;
              color: #0a0c26!important;
            }
          }

          &.planet-2 {
            .card-title {
              color: #ff4c99 !important;
            }
            .card-category {
              background-color: #ff4c99;
              color: #0a0c26!important;
            }
          }
          
          .md-card-header {
            &.md-card-avatar {
              max-width: 230px;
              max-height: 230px;
              margin: -100px auto 0;
              box-shadow: none;
            }
            background-color: transparent;
          }

          .md-card-content {
            margin-top: 0;
            padding: 1rem 1.875rem 2rem;
            min-height: 260px;

            .card-title {
              font-size: 22px;
              color: #2f2b5a;
              font-weight: 800;
            }
            .card-category {
              font-weight: 800;
              border-radius: 10rem;
              font-size: 13px;
              padding: 2px 10px 1px;
              display: inline-block;
              margin-top: 5px;
              margin-bottom: 5px;

            }
            .card-description {
              color: #c0c0c0;
              font-size: 14.5px;
              font-weight: 500;
              line-height: 1.6em;
              margin: 10px 0 10px
            }
          }

        }
      }

      .minting-bi-wrap {
        text-align: center;

        .minting-bi {
          /*max-width: 500px;*/
          /*width: 100%;*/
          /*padding: 0 40px;*/
        }
      }

    }
  }

</style>
